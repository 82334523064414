// scss-lint:disable ImportantRule
// Force style for daterangepicker

.daterangepicker {
  padding: 4px !important;

  .calendar-table {
    border: 1px solid #ddd;
    border-radius: 2px;
  }

  .input-mini {
    border-radius: 2px;
  }

  .calendar-time {
    text-align: left;
  }

  .ranges {
    clear: both;
  }
}

// scss-lint:disable QualifyingElement
// Copied from vendor/assets folder during migration to webpack.

table.treetable {
  tr.collapsed span.indenter a::before {
    color: #aaa;
    content: "+";
    cursor: pointer;
    font-size: 1.2em;
  }

  tr.expanded span.indenter a::before {
    color: #aaa;
    content: "–";
    cursor: pointer;
    font-size: 1.2em;
  }
}

@font-face {
  font-family: "crops-icons";
  src: url("crops-icons.woff2") format("woff2"),
       url("crops-icons.ttf") format("truetype");
}

.crop-icon {
  font-family: "crops-icons";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  text-transform: none;

  &-avena_spring::before,
  &-avena_winter::before {
    content: "\f101";
  }

  &-barley_spring::before,
  &-barley_winter::before {
    content: "\f102";
  }

  &-buckwheat::before {
    content: "\f103";
  }

  &-chickpea::before {
    content: "\f104";
  }

  &-cotton::before {
    content: "\f105";
  }

  &-custom::before {
    content: "\f106";
  }

  &-empty::before {
    content: "\f107";
  }

  &-fallow::before {
    content: "\f108";
  }

  &-grapefruit::before {
    content: "\f109";
  }

  &-grapevine::before {
    content: "\f10a";
  }

  &-lemon::before {
    content: "\f10b";
  }

  &-lentil::before {
    content: "\f10c";
  }

  &-lime::before {
    content: "\f10d";
  }

  &-linum::before {
    content: "\f10e";
  }

  &-maize::before {
    content: "\f10f";
  }

  &-medicago::before {
    content: "\f110";
  }

  &-millet::before {
    content: "\f111";
  }

  &-oil_seed_raps_spring::before,
  &-oil_seed_raps_winter::before {
    content: "\f112";
  }

  &-orange::before {
    content: "\f113";
  }

  &-papaver::before {
    content: "\f114";
  }

  &-pea::before {
    content: "\f115";
  }

  &-peach::before {
    content: "\f116";
  }

  &-peanut::before {
    content: "\f117";
  }

  &-pomegranate::before {
    content: "\f118";
  }

  &-pomelo::before {
    content: "\f119";
  }

  &-potatoes::before {
    content: "\f11a";
  }

  &-rice::before {
    content: "\f11b";
  }

  &-rye_spring::before,
  &-rye_winter::before {
    content: "\f11c";
  }

  &-safflower::before {
    content: "\f11d";
  }

  &-sainfoin::before {
    content: "\f11e";
  }

  &-sorghum::before {
    content: "\f11f";
  }

  &-soya::before {
    content: "\f120";
  }

  &-sudan_grass::before {
    content: "\f121";
  }

  &-sugar_beet::before {
    content: "\f122";
  }

  &-sugarcane::before {
    content: "\f123";
  }

  &-sunflower::before {
    content: "\f124";
  }

  &-triticale_spring::before,
  &-triticale_winter::before {
    content: "\f125";
  }

  &-wheat_spring::before,
  &-wheat_winter::before {
    content: "\f126";
  }
}
